import Loadable from "react-loadable"
import { Loading } from "../components"

// import Login from "./Login"
// import Dashboard from "./Dashboard"
// import Article from "./Article"
// import ArticleEdit from "./Article/Edit"
// import Settings from "./Settings"
// import NoFound from "./NoFound"
const NoFound =Loadable({
    loader:()=>import("./NoFound"),
    loading:Loading
})
const Home =Loadable({
    loader:()=>import("./Home"),
    loading:Loading
})

const Agreement =Loadable({
    loader:()=>import("./Agreement"),
    loading:Loading
})

export{
    NoFound,
    Home,
    Agreement
}