import React from "react"
import ReactDOM from "react-dom"
import App from "./App.js"
import './index.less'
import zhCN from 'antd/es/locale/zh_CN';
import {ConfigProvider} from "antd";
import { HashRouter as Router ,Route,Switch,Redirect} from "react-router-dom"
import {mainRoutes} from "./routes"


ReactDOM.render(
    <ConfigProvider locale={zhCN}>
          <Router>
            <Switch>
                <Route  path='/' render={
                    (routePropos)=>{
                        return <App {...routePropos} />
                    }
                } />
                {
                    mainRoutes.map(route =>{
                        return <Route key={route.pathname} path={route.pathname} component={route.component} />
                    })
                }
                <Redirect to='/' from='/' exact />
                <Redirect to='/404' />

            </Switch>
          </Router>
    </ConfigProvider>,

    document.querySelector("#root")

)
