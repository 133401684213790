import{
    NoFound,
    Home,
    Agreement
} from "../views"
import { WindowsOutlined, PieChartOutlined, RadarChartOutlined,BoxPlotOutlined  } from '@ant-design/icons'


export const mainRoutes=[
//    {
//    pathname: '/login',
//    component: Login,
//    title:'登录'
// },
{
    pathname: '/404',
    component: NoFound
 }
 
]

export const adminRoutes=[{
    pathname: '/home',
    component: Home,
    title:'首页'
 },
 {
    pathname: '/agreement',
    component: Agreement,
    title:'协议'
 }
]