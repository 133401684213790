import React, { Component } from 'react';
import {Route,Switch,Redirect} from "react-router-dom"
import { adminRoutes } from "./routes"
import 'antd/dist/antd.css'

class App extends Component {

    render() {
        return (
                 <Switch>
                    {
                       adminRoutes.map(route =>{
                           return <Route
                           key={route.pathname}
                           path={route.pathname}
                           render={(routePrpos)=>{
                               return <route.component {...routePrpos} />
                           }}
                           exact={route.exact}
                           />
                       })
                    }
                    <Redirect to={adminRoutes[0].pathname } from ='/' exact/>
                    <Redirect to='/404'/>                
                </Switch>
        )
    }
}

export default App
